@import "config";
@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
}

body {
  @include background();
  background-color: $primary-color;
  color: set-text-color($primary-color);
  font-family: "Roboto", sans-serif;
  height: 100%;
  margin: 0;
  line-height: 1.5;
  letter-spacing: 1px;
}

// Headings
main {
  margin: 0;
  font-weight: 400;

  .lg-heading {
    font-size: 4rem;
  }

  .sm-heading {
    font-size: 1.75rem;
    margin-bottom: 2rem;
    padding: 0.2rem 1rem;
    background: rgba(lighten($primary-color, 2), 0.5);
  }
}

a {
  color: #fff;
  text-decoration: none;
  // To remove the outer box which appears when link is clicked
  outline: 0;
}

.text-secondary {
  color: $secondary-color !important;
}

.navbar {
  &.nav-color {
    background-color: #2b2b2b;
  }

  .container {
    .navbar-brand {
      img {
        width: 60px;
        height: 60px;
      }
    }

    .navbar-toggler {
      box-shadow: none;
      border-color: white;

      &:focus,
      &:active {
        outline: none;
      }
    }

    #responsive-navbar-nav {
      .navbar-nav {
        .nav-link {
          font-size: 1.4rem;
          color: #fff;
          &:hover,
          &.active {
            color: $secondary-color !important;
          }
        }
      }
    }
  }
}

main {
  padding: 3rem;
  text-align: center;
  align-items: center;
  // height: 100%;
  // (Viewport height - footer height)
  // To make footer sticky
  min-height: calc(100vh - 50px);

  .icons {
    margin-top: 1rem;

    a {
      padding: 0.5rem;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  &#home,
  &#about,
  &#project {
    overflow: hidden;

    h1 {
      margin-top: 10vh;
    }
  }

  &#about {
    .lg-heading {
      color: $secondary-color;
    }
  }
}

.about-info {
  display: grid;
  grid-gap: 20px;
  place-items: center;
  text-align: left;
  font-size: 1.3rem;

  .bio-text {
    .intro,
    .tech {
      color: $secondary-color;
    }

    .open-head {
      color: #24cee0;
      &:hover {
        color: $secondary-color;
        text-decoration: underline;
        text-decoration-color: $secondary-color;
      }
    }

    ul {
      li {
        a {
          font-weight: bold;
          color: $secondary-color;

          &:hover {
            color: #24cee0;
            text-decoration: underline;
            text-decoration-color: #24cee0;
          }
        }
      }
    }
  }
}

// Projects
.projects {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(3, 1fr);

  img {
    height: 40vh;
    width: 100%;
    border: 3px rgb(52, 223, 200) solid;

    &:hover {
      opacity: 0.3;
      border-color: $secondary-color;
      @include easeOut;
    }
  }
}

// Button styles
.btn {
  display: block;
  padding: 0.5rem;
  border: 0;
  margin-bottom: 0.3rem;

  &:hover {
    background-color: $secondary-color;
    color: set-text-color($secondary-color);
  }
}

.btn-dark {
  @extend .btn;
  background: darken($primary-color, 50);
  color: #fff;
}

.btn-light {
  @extend .btn;
  margin-top: 0.3rem;
  background: lighten($primary-color, 50);
  color: #333;
}

#main-footer {
  text-align: center;
  padding: 1rem;
  background: darken($color: $primary-color, $amount: 10);
  height: 50px;

  .name {
    color: $secondary-color;
    font-weight: bolder;
  }
}

@import "mobile";

// Widescreens
@include mediaXl {
  .projects {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Desktops & Laptops
@include mediaLg {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}

// Tablets and Small laptops
@include mediaMd {
  main {
    .lg-heading {
      font-size: 3rem !important;
      line-height: 1;
      margin-bottom: 1rem;
    }

    .sm-heading {
      font-size: 1rem !important;
      // width: 310px;
    }
  }

  .about-info {
    font-size: 1rem;
  }

  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Smartphones
@include mediaSm {
  main {
    &#home,
    &#about {
      h1 {
        margin-top: 20vh;
      }
    }
  }

  .about-info {
    place-items: center;
    text-align: left;
    font-size: 1rem;
  }

  .projects {
    grid-template-columns: 1fr;
  }
}

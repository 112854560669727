// Partial config file
@use "sass:string";

$primary-color: #444;
$secondary-color: #a2e464;
$max-width: 650px;
$show-home-image: true;
$home-image: url("../assets/bg.png");
$background-opacity: 0.2;
$menu-opacity: 0.9;

@mixin easeOut {
  transition: all 1s ease-out;
}

@mixin background {
  @if $show-home-image {
    background: $home-image;
    background-attachment: fixed;
    background-size: cover;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: rgba($primary-color, $background-opacity);
    }
  }
}

// Set text-color
@function set-text-color($color) {
  @if (lightness($color) > 40) {
    @return #000000;
  } @else {
    @return #ffffff;
  }
}

// Media Query mixins

@mixin mediaSm {
  // width cannot be more than 500px
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  // width cannot be more than 768px
  @media screen and (max-width: 768px) {
    // @import "menu";
    @content;
  }
}

@mixin mediaLg {
  // width cannot be less than 769px
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  // width cannot be less than 1171px
  @media screen and (min-width: 1171px) {
    @content;
  }
}
